@font-face {
    font-family: 'FarnhamHeadline';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("FarnhamHeadline"), url("https://use.typekit.net/af/0120d9/00000000000000007750b3fb/30/l?primer=388f68b35a7cbf1ee3543172445c23e26935269fadd3b392a13ac7b2903677eb&fvd=n4&v=3") format("woff2"),
    url("https://use.typekit.net/af/0120d9/00000000000000007750b3fb/30/d?primer=388f68b35a7cbf1ee3543172445c23e26935269fadd3b392a13ac7b2903677eb&fvd=n4&v=3") format("woff");

    /*url(https://wehco-files.nyc3.cdn.digitaloceanspaces.com/new_site/font/farnham_headline/Farnham_Headline_Regular.woff) format('woff');*/
}

@font-face {
    font-family: 'FarnhamHeadlineFallbackGeorgia';
    font-style: normal;
    font-weight: 400;
    src: local("Georgia");
    ascent-override: 89.3%;
    descent-override: 26.6%;
}

@font-face {
    font-family: 'FarnhamHeadlineMedium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://wehco-files.nyc3.cdn.digitaloceanspaces.com/new_site/font/farnham_headline/Farnham_Headline_Medium.woff) format('woff');
}

@font-face {
    font-family: 'FreightTextBook';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://wehco-files.nyc3.cdn.digitaloceanspaces.com/new_site/font/freighttext_pro/freighttext_pro_book.woff2) format('woff2');
}


@font-face {
    font-family: "FarnhamHeadlineSemiBold";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://use.typekit.net/af/b52183/00000000000000007750b3fd/30/l?primer=388f68b35a7cbf1ee3543172445c23e26935269fadd3b392a13ac7b2903677eb&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/b52183/00000000000000007750b3fd/30/d?primer=388f68b35a7cbf1ee3543172445c23e26935269fadd3b392a13ac7b2903677eb&fvd=n6&v=3") format("woff");

            /*url(https://wehco-files.nyc3.cdn.digitaloceanspaces.com/new_site/font/farnham_headline/Farnham_Headline_Semi_Bold.woff) format('woff');*/
}


@font-face {
    font-family: "FarnhamHeadlineSemiLighht";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://wehco-files.nyc3.cdn.digitaloceanspaces.com/new_site/font/farnham_headline/Farnham_Headline_Semi_Light.woff) format('woff');
}

@font-face {
    font-family: "FarnhamTextRegular";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://use.typekit.net/af/804927/00000000000000007739bdf4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/804927/00000000000000007739bdf4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff");

            /*url(https://wehco-files.nyc3.cdn.digitaloceanspaces.com/new_site/font/farnham_text/farnham_text_regular.woff2) format('woff2');*/
}

@font-face {
    font-family: "Farnham-text-fallback-georgia";
    font-style: normal;
    font-weight: 400;
    src: local("Georgia");
}

@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://wehco-files.nyc3.cdn.digitaloceanspaces.com/new_site/font/oswald/oswald.woff2) format('woff2');
}

@font-face {
    font-family: "OswaldDemiBold";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://wehco-files.nyc3.cdn.digitaloceanspaces.com/new_site/font/oswald/oswald-demibold.woff2) format('woff2');
}

@font-face {
    font-family: "OswaldFallbackArial";
    size-adjust: 81.66000000000005%;
    ascent-override: 144%;
    descemt-override: 54%;
    src: local("Arial");
}

/*
@font-face {
    font-family: "OswaldFallbackArial";
    font-style: normal;
    font-weight: 400;
    src: local("Arial");
    ascent-override: 109%;
    descent-override: 26.76%;
}
*/

.headline_font_loaded {
    font-family: var(--serif-main), serif;
    letter-spacing: 0!important;
    word-spacing: 0!important;
}

.sans_font_loaded {
    font-family: var(--sans-main), sans-serif;
    letter-spacing: 0!important;
    word-spacing: 0!important;
}

.headline_fallback_font {
    font-family: var(--serif-main-fallback), serif; /*fallback is Georgia*/
    letter-spacing: -0.45px;
    word-spacing: -0.4px;
}

.copy_text_loaded {
    font-family: var(--serif-body-text), serif;
    letter-spacing: 0!important;
    word-spacing: 0!important;
}

.copy_fallback_font {
    font-family: var(--serif-copy-fallback), serif; /*fallback is Georgia*/
    letter-spacing: -0.1px;
}

.oswald_fallback_font {
    font-family: var(--sans-main-fallback), sans-serif;
}

.sub-ex-fallback {
    font-family: "OswaldFallbackArial", sans-serif;
    font-weight: 600;
    letter-spacing: 0.1px;
    word-spacing: -0.65px;
}

.sub-ex-font {
    font-family: "OswaldDemiBold", sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
}







