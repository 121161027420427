.ad_position {
    background-color: #C6C6C6;
}

.ad__desktop {
    display: none;
}

.ad__mobile {
    display: block;
}

.x300x250 {
    width: 300px;
    height: 250px;
    }



.x300x600 {
    width: 300px;
    height: 600px;
}

.top_ad_position {
    width: 100%;
    height: 70px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.lede_ad {
    width: 300px;
    height: 250px;
}

.long_billboard {
    display: none;
    aspect-ratio: 1 / 2;
    height: 600px;
    width: 300px;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    position: sticky;
    top: 200px;
}

.btf_ad_unit_vertical {
    display: none;
    align-self: center;
}


.btf_ad_unit_hoz {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        grid-column: 1/-1;
        height: 250px;
        justify-content: center;
        margin: 1em auto;
        width: 300px;
    }


@media (min-width: 60em){
    .btf_ad_unit_hoz {
        display: none;
    }

    .btf_ad_unit_vertical {
        display: flex;
        flex-flow: column nowrap;
    }
}


@media (min-width: 64em){
    .top_ad_position {
        height: 270px;
    }

    .ad__mobile {
        display: none;
    }

    .ad__desktop {
        display: block;
    }
}




@media (min-width: 48em) {
    .long_billboard {
        display: flex;
        flex-direction: column;
    }

    .lede_ad {
        display: none;
    }
}

