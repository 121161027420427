.sidenav {
    height: 100%;
    width: 20rem;
    position: fixed;
    z-index: 100;
    /*top: 0;
    left: 0;
     */
    background-color: var(--black);
    overflow-x: hidden;
    transition: 0.3s;
    /*padding-top: 60px;*/
}

.sidenav #sidenavLinks {
    display: block;
}

.sidenav a:hover, .sidenav-section-title:hover, .sidenav-weather-text:hover{
    color: var(--accent-blue);
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 0.7rem;
    font-size: var(--fs-24);
    color: var(--white)
}

.sidenavList {
    display: flex;
    flex-direction: column;
    padding-inline-start: 0;
}

.sidenav-search {
    color: var(--white);
    width: 90%;
    padding: 0.5rem;
    margin: 0 auto;
}
.sidenav-weather {
    display: inline-flex;
    width: 90%;
    margin: 0 auto;
    padding: 0.5rem;
    align-items: center;
}
.sidenav-weather-icon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
}
.sidenav-weather-text {
    color: white;
    font-size: var(--fs-18);
    padding-left: 5px;
    margin-left: 0.5rem;
    font-family: var(--ff-sans-serif);
    transition: 0.3s;
}
.search-btn {
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid var(--white);
    background: var(--white);
    text-align: center;
    color: var(--black);
    border-radius: 0 3px 3px 0;
    cursor: pointer;
    font-size: var(--fs-16--base);
}
.search-input {
    font-family: "Poppins", sans-serif;
    padding: 0.5rem 1rem;
    width: 100%;
    height: 2.5rem;
    color: #525252;
    letter-spacing: 2px;
    border: none;
    border-radius: 3px 0 0 3px;
    outline: none;
}
.sidenav-search > .fa-search {
    margin: 0.5rem 0.5rem 0.5rem 0 ;
}
.sidenav-section {
    margin: 1rem 0 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.sidenav-section-title{
    color: var(--white);
    font-size: var(--fs-18);
    background-color: var(--black);
    border: none;
    padding: 0;
    cursor: pointer;
}

.sidenav-section-title::after {
    content: url("data:image/svg+xml,%3Csvg height='1em' width='1em' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath opacity='.4' d=''/%3E%3Cpath fill='white' stroke='white' d='M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z'/%3E%3C/svg%3E");
    height: 1em;
    width: 1em;
    display: inline-block;
    vertical-align: text-bottom;
}

.sidenav-section-title:first-child::after {
    content: unset;
}

.sidenav-section-list{
    margin: 0 0.5rem;
    padding-left: 1rem;
    font-size: var(--fs-16--base);
}
.sidenav-section-list a {
    /* padding: var(--s0); */
    text-decoration: none;
    font-size: var(--fs-16--base);
    color: var(--white);
    transition: 0.3s;
    font-family: var(--ff-sans-serif)
}

.user-control--signin {
    color: var(--black);
}

.sidenav-user-control-buttons {
    display: flex;
    justify-content: center;
    gap: var(--s0);
}

.sidenavList {
    margin: 1em 0;
}
.sidenav-section-content a{
    font-family:var(--ff-sans-serif)
}
.sidenav-section-title {
    display: block;
}
.sidenav-section-title svg{
    vertical-align: text-bottom;
}

.sidenav-user-control-buttons {
    height: 40px;
    gap: 1em;
}

.user-control--subscribe.button {
    background-color: var(--yellow);
    color: var(--black);
}

.user-control--manage.button, .user-control--signin.button {
    color: var(--black);
}

.hidden {
    display: none;
}

.visible {
    display: block;
}