*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    /*font-size: var(--fs-16--base);
    /*line-height: var(--s2);
    font-family: serif;*/
    min-height: 100vh;
    color: var(--text-color);

}

.font--poppins--regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.wrapper>* {
    max-width: var(--content-max-width);
    margin-left: auto;
    margin-right: auto;
}

.header {
    position: sticky;
    top: 0;
    background: var(--white);
    z-index: 9;
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid var(--border-dark);
}

.header-content {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: var(--s1);
    color: var(--black);
    padding: var(--s0) 0;
    width: auto;
    max-width: var(--content-max-width);
    margin: 0 auto;
}

.header--icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
}

.btn--icon {
    border-radius: var(--btn-icon-border-radius);
    height: 1.5em;
    width: 1.5em;
    cursor: pointer;
    margin-left: 0.75em;
}

.btn--icon svg {
    height: 100%;
    width: 100%;
}

.btn--hamburger {
    padding-left: 0;
    padding-right: 0;
}


.fa {
    display: inline-block;
    font: normal normal normal 16px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: var(--black);
}

.logo__img {
    max-width: 100%;
    object-fit: contain;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header--buttons {
    display: flex;
    justify-content: end;
    margin:0;
    align-items: center;
}
.breaking-bar {
    background-color: var(--accent-red);
    color: var(--white);
    padding: var(--s-2) 0;
}
.breaking-bar-content {
    width: auto;
    max-width: var(--content-max-width);
    margin: 0 auto;
}

.breaking-bar-content a:hover {
    color: white;
    text-decoration: underline;
}

.warning-bar {
    background-color: var(--yellow);
    color: var(--black);
    padding: var(--s-2) 0;
}
.warning-bar-content {
    width: auto;
    max-width: var(--content-max-width);
    margin: 0 auto;
}



.button {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    /* gap: var(--s-3); */
    border: none;
    border-radius: 3px;
    padding: var(--s-3) var(--s0);
    font-size: var(--fs-16--base);
    text-decoration: none;
    cursor: pointer;
    color: var(--white);
    /* font-family: "Poppins", sans-serif;; */
}

/*.button--primary{
    background-color:var(--yellow);
    color:var(--black);
  }*/



.btn--search1 {
    /* border: .0625rem solid #fff; */
    border-radius: .25rem;
    height: 2rem;
    justify-content: center;
    margin-left: 0.25rem;
    width: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

@media only screen and (max-width: 480px){
    .header__logo__link .logo__img {
        height: 1rem;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 639px) {
    body {
        font-size: var(--fs-18);
        /*line-height: var(--s2);*/
    }
    .user-control-buttons {
        display: none;
    }

    .logo__img {
        height: 1.25rem;
    }

    .btn--icon {
        height: 1.25rem
    }

    .btn--eedition, .btn--search, .btn--weather {
        display: none;
    }

    .top-ad-mobile {
        background-color: var(--dark-grey);
        color: var(--black);
        height: 5rem;
    }

}
/* Medium devices (landscape tablets) */
@media only screen and (min-width: 640px) {

    body {
        font-size: var(--fs-16--base);
        /*line-height: var(--s2);*/
    }

    .logo__img {
        height: 1.5em;
    }


}
/* Large devices (laptops/desktops) */
@media only screen and (min-width: 960px) {
    body {
        font-size: var(--fs-16--base);
        /*line-height: var(--s2);*/
    }
    p {
        margin-top: 1rem;
    }

    .btn--icon {
        height: 1.5em;
        width: 1.5em;
    }
}