.header__user-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-right: 1em;
}

/********** Menu Button **********/

.header__user-controls__menu-button {
    /* Hidden on larger screens when the menu attribute is not "true" */
    display: none;

    /* General styling */
    align-items: center;
    font-size: 1rem;
    cursor: pointer;

    /* Remove button styling */
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    box-shadow:none;

    /*height:1.5em;*/
    width:1.5em;
    margin-right: 1em;
}

[data-menu="true"] .header__user-controls__menu-button {
    /* Unhide the button */
    display: inline-flex;
}

@media (max-width: 60rem) {
    .header__user-controls__menu-button {
        /* Unhide the button */
        display: inline-flex;
    }
}

[aria-expanded="false"] .header__user-controls__menu-button__close {
    /* Hide the close icon when the menu is closed */
    display: none;
}

[aria-expanded="true"] .header__user-controls__menu-button__open {
    /* Hide the open icon when the menu is open */
    display: none;
}

/********** Menu **********/

.header__user-controls__menu {
    background-color: var(--white);
    color: var(--black);
    margin-right:var(--fs-16--base)
}

[data-menu="true"] .header__user-controls__menu {
    /* Hidden by default */
    display: none;
    z-index: -1;

    /* Stretched over entire screen */
    position: absolute;
    left: 0;
    right: 0;
    min-height: 100vh;

    /* don't overlay header */
    top: 56px;

    border-top: 1px solid var(--black);
}

@media (max-width: 60rem) {
    .header__user-controls__menu {
        /* Hidden by default */
        display: none;
        z-index: -1;

        /* Stretched over entire screen */
        position: absolute;
        left: 0;
        right: 0;
        min-height: 100vh;

        /* don't overlay header */
        top: 56px;
        border-top: 1px solid var(--black);
        width: 100vw;
    }
}

[aria-expanded="true"] + .header__user-controls__menu {
    /* Unhidden */
    z-index: 999;

    /* Flexbox to make the menu appear as a column */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /* Ensure content does not touch left and right screen edges */
    padding: 0 var(--s0);
    overflow: hidden;
}

/********** Menu Content **********/

.header__user-controls__menu__content {
    display: flex;
    gap: var(--s0);
    justify-content: flex-end;
    color: var(--black);
    /*font-family: var(--sans-main), sans-serif;*/
}


[data-menu="true"] .header__user-controls__menu__content {
    /* Flex */
    flex-direction: column;
    align-items: stretch;

    /* Box sizing */
    max-width: var(--content-max-width);
    margin: var(--s2) auto;
}

[data-menu="true"] .header__user-controls__menu__content .button {
    /* Full width (by replacing default inline-flex) */
    display: flex;

    /* Aesthetics */
    min-width: 15rem;
}


@media (max-width: 60rem) {
    .header__user-controls__menu__content {
        /* Flex */
        flex-direction: column;
        align-items: stretch;

        /* Box sizing */
        max-width: var(--content-max-width);
        margin: var(--s2) auto;

        /*font-family: var(--sans-main), sans-serif;*/
    }

    .header__user-controls__menu__content .button {
        /* Full width (by replacing default inline-flex) */
        display: flex;

        /* Aesthetics */
        min-width: 15rem;
    }
}

/********** Buttons **********/

.header__user-controls__menu__button {
    border: 1px solid var(--black);
    color: var(--black);
}

.header__user-controls__menu__button--subscribe {
    color: var(--black);
    border: 1px solid var(--yellow);
    background-color: var(--yellow);
}

.donate {
    background-color:#4A73B9;
    color:white;
}