@media (min-width: 1120px) {

    .lede {
        display: grid;
        grid-template-columns: minmax(300px, 1fr) 1px 2fr 1px minmax(300px, 1fr);
        grid-column-gap: var(--s1);
    }

    .latest {
        position: sticky;
        display: flex;
        flex-direction: column;
        margin: 0;
        grid-column: 5/6;
        grid-row: 1 / 3;
        top: 0;
        border-bottom: none;
        /*border-left: 1px solid var(--border-dark);*/
    }

    .latest_wrapper {
        position: sticky;
        top: 100px;
    }

    .latest__stories__list {
        display: flex;
        flex-direction: column;
        gap: 0;
    }


    .section {
        grid-column: 1 / 4;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: var(--s0);
        grid-row-gap: var(--s-1);
    }



    .section__lede {
        grid-column: 1/2;
        display: flex;
        flex-flow: column nowrap;

    }

    .section__secondary {
        grid-column: 2/3;
        display: flex;
        flex-flow: column nowrap;
    }

    .text_section {
        grid-column: span 2;
    }

    .right_rail_ad.long {
        flex-direction: column;
    }

    .top_ad {
        display: none;
    }

    .lede_ad {
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .featured_tops {
        grid-column: 3/4;
        grid-row: 2;
    }
}

@media (min-width: 768px) and (max-width:1120px) {
    .lede {
        display: grid;
        grid-template-columns: 300px 1px 1fr;
        grid-gap: var(--s1);
    }




    .left-border {
        background-color: var(--border-light);
        grid-column: 4/5;
        grid-row: 1 / 3;
        display: block;
    }

    .top_stories {
        flex-basis: 25%;
        min-width: 15em;
    }

    .latest {
        grid-column: 3 / 4;
        grid-row: 3;
        margin: var(--s0) 0;
        padding: 0 0 var(--s0);
    }

    .section {
        grid-column: 1 / 3;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1em;
    }

    .section__lede {
        grid-column: 1/2;
        display: flex;
        flex-flow: column nowrap;
    }

    .section__secondary {
        grid-column: 2/3;
        display: flex;
        flex-flow: column nowrap;
    }

    .section__extra {
        grid-column: 3/4;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
    }

    .text_section {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .text_section h2.section__heading {
        display: block;
        width: 40%;
    }

    ul.featured_stories_list li div {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        margin-top: 0.5em;
        align-items: center;
        justify-content: center;
    }

    ul.featured_stories_list li div h4 {
        order: 2;
    }

    ul.featured_stories_list li div img {
        order: 1;
        height: auto;
        width: 100%;
    }

    .top_stories {
        /*border-right: 1px solid var(--border-dark);*/
        grid-column: 1 / 2;
        grid-row: 1 / span 3;
    }

    .top_ad {
        display: flex;
        position: sticky;
        top: 100px;
        flex-direction: row;
        justify-content: center;

    }

    .lede_ad {
        display: none;
    }

    .featured_tops {
        grid-column: 3/4;
        grid-row: 3;
    }


    .latest {
        grid-row: 2;
    }

}


@media (min-width: 768px) {

    /*.left-border {
        background-color: var(--border-light);
        grid-column: 2/3;
        grid-row: 1 / 4;
    }

    .ledestory {
        /*flex-basis: 30%;
        display: flex;
        flex-direction: column;
        flex-shrink: 2;
        flex-grow: 4;
        grid-column: 3 / 4;
        border-bottom: none;
    }

    .ledestory_img {
        width: 100%;
        position: static;
        margin: 0 0 var(--s-1);
        max-width: 100%;
        order: 1;
    }*/

    .content_wrapper {
        padding: 0 var(--s0);
    }

    .top_stories {
        /*border-right: 1px solid var(--border-dark);*/
        grid-column: 1 / 2;
        border-bottom: none;
    }

    .top_stories__list {
        border-bottom: 1px solid var(--border-light);
    }



   /* .featured ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-flow: row;
        grid-template-rows: auto;
        grid-row-gap: 2em;
        font-size: var(--fs-14);
        font-weight: 500;
        grid-column-gap: 2em;
    }

    .featured ul li {
        padding: 0 1em 0 0;
        border-right: 1px solid var(--border-light);
        border-bottom: none;
    }

    .featured ul li:last-child {
        padding-right: 0;
        border-right: none;
    }

    .featured ul.media li {
        max-width: 180px;
        border-right: none;
        padding-right: 1em;
    }*/




    /*.featured ul li:after {
        content: " ";
        height: calc(100% + 1px);
        position: absolute;
        top: 0;
        right: 2em;
        border-right: 1px solid var(--border-dark);
    }*/
/*
    .reverse_section {
        grid-column: 1 / 4;
        grid-row: span 1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1em;
    }

    .reverse_section__lede {
        grid-column: 2/4 ;
        order: unset;

    }

    .reverse_section__secondary {
        grid-column: 1 /2;
        order: unset;
    }


    .featured__story {
        flex-direction: row;

        justify-content: center;
    }

    .featured__img {
        margin-bottom: 2em;
    }

    .events {
        height: 250px;
        width: 90%;
        margin: 0 auto;
    }

    ul.featured_stories_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }*/

}


@media (min-width: 640px) and (max-width: 767px) {
    .content_wrapper {
        padding: 0 var(--s2);
    }

}