.archive_nav{
    margin:1rem;
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    grid-template-areas:
    "left center right"
  }
  .archive_nav_right{
    grid-area:right;
    justify-self: end;
  }
  .archive_nav_left{
    grid-area:left;
    justify-self: start;
  }

  button {
    border-radius: .25rem;
    box-shadow: 0 0 .25rem rgba(51,51,51,.2);
    height: 2.5rem;
    text-transform: capitalize;
    transition: .4s;
  }

  .button--primary--search{
    background-color:var(--blue-links-buttons);
    color:white;
  }
  form{
  margin-bottom: 1rem;
}
  .form_button {
    background: #333;
    border: .0625rem solid #333;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: #fff;
    width: 2.75rem;
  }
  input {
    width:100%;
    padding-left:1rem;
    font-size: 1rem;
    border: 1px solid black;
  }
  label{
    font-weight:bold;
  }
  select {
    appearance: none;
    background: transparent;
    border: .0625rem solid #333;
    border-radius: .25rem;
    color: #333;
    font-size: 1rem;
    height: 2.5rem;
    padding: .5rem;
    padding-left: 1rem;
    position: relative;
    transition: .4s;
  }

  

