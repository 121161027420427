:root {
    --ratio: 1.5;
    --s-5: calc(var(--s-4) / var(--ratio));
    --s-4: calc(var(--s-3) / var(--ratio));
    --s-3: calc(var(--s-2) / var(--ratio));
    --s-2: calc(var(--s-1) / var(--ratio));
    --s-1: calc(var(--s0) / var(--ratio));
    --s0: 1rem;
    --s1: calc(var(--s0) * var(--ratio));
    --s2: calc(var(--s1) * var(--ratio));
    --s3: calc(var(--s2) * var(--ratio));
    --s4: calc(var(--s3) * var(--ratio));
    --s5: calc(var(--s4) * var(--ratio));
    --viewport-horizontal-space: var(--s0);
    --content-vertical-space: var(--s0);
    --content-max-width: 75rem;

    --black: #333;
    --white: #fff;
    --red: #B02928;
    --light-grey: #f6f6f6;
    --grey: #ececec;
    --dark-grey: #cacaca;
    --yellow: #e5a624;
    --blue: #165aaa;
    --blue-links-buttons: #4D80B3;
    --transparent: rgba(0, 0, 0, 0);


    --fs-8: 0.5rem;
    --fs-10: 0.625rem;
    --fs-12: 0.75rem;
    --fs-14: 0.875;
    --fs-16--base: 1rem;
    --fs-18: 1.125rem;
    --fs-19: 1.2rem;
    --fs-20: 1.25rem;
    --fs-21: 1.33rem;
    --fs-23: 1.44rem;
    --fs-24: 1.5rem;
    --fs-28: 1.75rem;
    --fs-30: 1.875rem;
    --fs-32--main-title: 2rem;
    --fs-33: 2.074rem;
    --fs-38: 2.4rem;
    --fs-48: 3rem;

    --btn-icon-width: 2rem;
    --btn-icon-height: 2rem;
    --btn-icon-border-radius: 0.25rem;

    --ff-sans-serif: sans-serif;
    --serif-main: "FarnhamHeadline";
    --serif-main-fallback: "FarnhamHeadlineFallbackGeorgia";
    --serif-main-light: "FarnhamHeadlineSemiLighht";
    --serif-body-text: "FarnhamTextRegular";
    --serif-copy-fallback: "Farnham-text-fallback-georgia";
    --sans-main: "Oswald";
    --sans-main-fallback: "OswaldFallbackArial";
    --sub-ex-font: "OswaldDemiBold";


    --line-height-small: 1.2;
    --line-height-text: 1.4;

    --text-color: #555555;
    --accent-blue: #0066b2;
    --accent-red: #B02928;
    --border-light: rgba(205, 205, 205, 0.7);
    --border-dark: #333;

}