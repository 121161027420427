footer {
    background-color: var(--light-grey);
    box-shadow: 0 50vh 0 50vh var(--light-grey);
}
.footer-content {
    padding: var(--s1);
    display: grid;
    grid-template-rows: 1fr auto auto;
    gap: var(--s-1);
    font-size: var(--fs-14);
}
.footer-content img {
    width: 300px;
    margin: 1rem auto;
}
.footer-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
ul.footer-links li {
    margin-right: var(--s-1);
    font-size: var(--fs-16--base);
    padding-right: var(--s-1);
    border-right: 1px solid var(--border-dark);
}

ul.footer-links li:last-child {
    border-right: none;
}