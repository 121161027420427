img {
    max-width: 100%;
    height: auto;
  }
  
  a {
    text-decoration: none;
    color: unset;
    cursor: pointer;
  }
  
  a:hover {
    color: var(--accent-blue);
    transition: color 0.3s;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 1.15;
    font-weight: 400;
    font-family: var(--serif-main-fallback), serif;
    color: var(--text-color);
    letter-spacing: -0.45px;
    word-spacing: -0.4px;
  }


  
  ul {
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    list-style: none;
  }
  
  .section__title {
    display: block;
    padding: 0;
    margin: 0;
    font-size: var(--fs-10);
    /*font-family: var(--sans-main), sans-serif;*/
    width: fit-content;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 0.5px solid black;
  }
  
  .sub__ex {
    display: block;
    width: fit-content;
    color: var(--accent-blue);
    font-weight: 500;
    margin: var(--s-5) 0;
    font-size: var(--fs-10);
    /*font-family: var(--sans-main), sans-serif;*/
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  .media_icon {
    margin-left: var(--s-3);
    color: var(--accent-blue);
  }
  
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .content_wrapper {
    display: block;
    max-width: 80em;
    margin: 0 auto;
    padding: 0 var(--s0);
  }
  
  .top_nav_link {
    display: flex;
    justify-content: center;
    /*border-bottom: 1px solid var(--border-dark);*/
    /*margin-bottom: var(--s-4);*/
    width:100%;
    margin:1em auto;
  }

  .arrow {
    border: solid var(--text-color);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin: 0 1em;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  
  .arrow:hover{
      cursor:pointer;
  }

  .top_nav_link ul {
    list-style: none;
    padding-inline-start: 0;
    display: flex;
    justify-content: start;
    /*font-family: var(--sans-main), sans-serif;*/
    font-size: var(--fs-14);
    margin: var(--s-4);
    align-items: center;
    overflow-x:auto;
    scrollbar-width: none;
  }
  .top_nav_link ul li {
    padding: 0 var(--s1);
    border-right: 1px solid var(--border-light);
    text-wrap:nowrap;
  }

.top_nav_link ul li a {
  display: flex;
  align-items: center;
}

  .top_nav_link ul li:last-child {
    border-right: transparent;
  }
  
  .lede {
    display: flex;
    flex-direction: column;
    margin: 0 0 var(--s0);
    /*padding-bottom: var(--s2);*/
  }



.ledestory {
  border-bottom: 1px solid var(--border-light);
  display: flex;
  flex-direction: column;
  order: 1;
  width: 100vw;
  max-width: unset;
  position: relative;
  left: 50%;
  right: 50%;
  margin: 0 -50vw var(--s1);
  background: #ededed;
  padding: var(--s1) var(--s2);
}
  .ledestory_img {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin: 0 -50vw var(--s-1) -50vw;
    max-width: unset;
    order: 3;
  }
  .ledestory_title {
    order: 2;
    font-family: FarnhamHeadlineSemiBold, serif;
    font-size: var(--fs-30);
    text-align: center;
    margin-bottom: var(--s-1);
    font-weight: 600;
  }
  .ledestory_text {
    order: 4;
  }
  .ledestory p {
    font-family: "FarnhamTextRegular", serif;
    font-size: var(--fs-16--base);
    margin: 0 0 var(--s-1);
    padding: 0 0 var(--s-1);
    order: 4;
  }
  .ledestory .section__title {
    order: 1;
    margin: 0 auto;
  }

  .ledestory .sub__ex {
    align-self: center;
  }
  
  .top_list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .top_list h3 {
    font-size: var(--fs-18);
  }

  .featured_tops .top_list h3 {
    font-size: var(--fs-20);
  }

  .top_list li {
    padding-bottom: var(--s0);
    margin-bottom: var(--s0);
    border-bottom: 1px solid var(--border-light);
  }

  .top_list li:last-child {
    border-bottom: transparent;
  }

  .top_list h3 {
    font-size: var(--fs-18);
  }
  .top_stories {
    order: 3;
    padding-bottom: var(--s0);
  }
  .latest {
    order: 4;
    padding-bottom: var(--s0);
  }
  .latest_title {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid var(--border-dark);
    padding: 0 0 var(--s0);
    margin: 0 0 var(--s0);
  }
  .latest_title h2 {
    /*font-family: var(--sans-main), sans-serif;*/
    font-size: var(--fs-30);
  }
  .timestamp {
    font-size: var(--fs-10);
    font-weight: 300;
    color: var(--accent-red);
    /*font-family: var(--sans-main);*/
    vertical-align: top;
    display: block;
  }
  .featured_tops {
    order: 2;
  }
  .featured_tops li {
    display: flex;
    flex-direction: row;
    column-gap: var(--s0);
    justify-content: space-between;
    position: relative;
  }
  .lede_ad {
    order: 4;
    margin: var(--s0) auto;
  }
  .long_ad {
    display: none;
  }
  .top_page_ad {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: grey;
  }

@media (min-width: 640px) {
    .top_nav_link ul {
      font-size: var(--fs-14);
    }
  }

@media (min-width: 768px) and (max-width: 1119px) {
  .lede {
    display: grid;
    grid-template-columns: 300px 1px 1fr;
    grid-gap: var(--s1);
    margin: var(--s0) 0;
  }

  .ledestory {
    grid-column: 3/4;
    grid-row: 1;
    max-width: unset;
    width: 100%;
    position: static;
    padding: 0;
    margin: 0;
  }

  .ledestory_img {
    width: 100% !important;
    position: static !important;
    margin: 0 0 var(--s-1) !important;
    max-width: 100% !important;
    order: 1 !important;
  }

  .ledestory_title {
    padding: var(--s1);
  }

  .ledestory_text {
    padding: 0 var(--s1);
    text-align: center;
  }

  .ledestory .sub__ex {
    order: 2;
  }
  .latest {
    grid-column: 3/4;
    grid-row: 3;
  }
  .featured_tops {
    grid-column: 3/4;
    grid-row: 2;
  }
  .top_stories {
    grid-column: 1/2;
    grid-row: 1/span 3;
  }

}

@media (min-width: 1120px) {
  .lede {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 1px 2fr 1px minmax(300px, 1fr);
    grid-column-gap: var(--s1);
    margin: var(--s0) 0;
  }
  .ledestory {
    grid-column: 3/4;
    grid-row: 1;
    border-bottom: none;
    margin: 0;
    position: static;
    width: 100%;
    padding: 0;
  }
  .ledestory_img {
    width: 100% !important;
    position: static !important;
    margin: 0 0 var(--s-1) !important;
    max-width: 100% !important;
    order: 1 !important;
  }

  .ledestory_title {
    padding: var(--s1);
  }

  .ledestory_text {
    padding: 0 var(--s1);
    text-align: center;
  }

  .ledestory .sub__ex {
    order: 2;
  }

  .featured_tops {
    grid-column: 3/4;
    grid-row: 2;
  }
  .top_stories {
    grid-column: 1/2;
    grid-row: 1/4;
  }
  .latest {
    grid-column: 5/6;
    grid-row: 1/3;
  }
  .latest_wrapper {
    position: sticky;
    top: 100px;
  }
}
