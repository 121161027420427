.card{
    display:flex;
    flex-direction:row-reverse;
    gap: 1rem;
    position: relative;
}
.card_title{
  font-weight:700;
  font-size:1.25rem;
}
.card_img{
height: 160px;
margin-bottom: 2em;
object-fit: contain;
padding: 0 0 2rem 0;
}
.card_text{
    flex-direction:column;
    display:flex;
    flex-grow:999;
    flex-basis:0;
    gap:1rem;
}

.card__link:after {
  content: "";
  position: absolute;
  inset: 0;
}

.main_title{
    line-height:1.2;
    font-size:2.5rem;
    font-weight:700;
  }
  
@media (min-width:40rem){
  img.card__thumbnail{
    flex-basis:15rem;
    flex-grow:1;
    width:15rem;
  }
}

@media (max-width: 40rem){
  .card{
    display:block;
  }
  img.card__thumbnail{
    width:100%;
    flex-basis:auto;
    margin:0 auto;
    display:flex;
  }
}