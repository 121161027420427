body .content_wrapper .breadcrumb-nav {
    grid-column: 1/-1;
    margin-top: var(--s0);
}
body .content_wrapper .breadcrumb-nav ul.breadcrumb {
    padding: var(--s-1) var(--s-2);
    list-style: none;
    font-family: var(--sans-main);
}
body .content_wrapper .breadcrumb-nav ul.breadcrumb li {
    display: inline;
}
body .content_wrapper .breadcrumb-nav ul.breadcrumb li:not(:last-child):after {
    padding: var(--s-2);
    color: var(--black);
    content: "|";
}
body .content_wrapper .breadcrumb-nav ul.breadcrumb li a {
    color: var(--blue-links-buttons);
}
body .content_wrapper .news_subsection {
    display: flex;
    flex-direction: column;
  }
  body .content_wrapper .news_subsection h1 {
    font-size: var(--fs-32--main-title);
    margin: 0 0 1rem;
    /*font-family: var(--sans-main), sans-serif;*/
    border-bottom:1px solid var(--black);
    padding:0 0 1rem;
  }
  body .content_wrapper .news_subsection .story_card_list {
    display: flex;
    flex-flow: column nowrap;
    gap: var(--s0);
  }
  body .content_wrapper .news_subsection .story_card_list li {
    border-bottom: 1px solid var(--grey);
    padding-bottom: var(--s0);
  }
  body .content_wrapper .news_subsection .story_card_list .story_card {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    gap: var(--s0);
  }

  body .content_wrapper .news_subsection .story_card_list .story_card .card_text {
    font-family: var(--serif-main-light), serif;
    flex-basis: 0;
    flex-grow: 999;
    display: flex;
    flex-direction: column;
    gap: var(--s-1);
    line-height: 1.5;
  }
  body .content_wrapper .news_subsection .story_card_list .story_card .card_text .card_title {
    font-family: var(--serif-main), serif;
    font-size: var(--fs-20);
  }