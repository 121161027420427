.news_section {
  display: flex;
  flex-direction: column;
}
.news_section div {
  position: relative;
}
.news_section h2 {
  display: inline-block;
  font-size: var(--fs-30);
  margin-bottom: var(--s-1);
  /*font-family: var(--sans-main), sans-serif;*/
}

.news_section h3 {
  font-size: var(--fs-18);
}
.news_section img {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin: 0 -50vw var(--s-1) -50vw;
  max-width: unset;
}
.news_section ul li {
  margin: var(--s0) 0;
  padding-bottom: var(--s0);
  border-bottom: 1px solid var(--border-light);
}
.news_section__lede {
  margin-bottom: var(--s0);
  padding-bottom: var(--s0);
  border-bottom: 1px solid var(--border-light);
}

.two_column, .four_column {
  grid-column: 1 / -1;
}

.below_the_fold {
  display: flex;
  flex-direction: column;
  row-gap: var(--s0);
}

/***four column***/



.news_section__four-column {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 var(--s0);
}


.four-column__title {
  font-size: var(--fs-30);
  margin-bottom: var(--s-1);
  /*font-family: var(--sans-main), sans-serif;*/
}

.hed__four-column {
  font-size: var(--fs-16--base);
}

.img__four-column {
  width: 100vw;
  left: 50%;
  right: 50%;
  margin: 0 -50vw var(--s-1) -50vw;
  position: relative;
  max-width: unset;
}

.four_column_story {
  border-bottom: 1px solid var(--border-light);
  padding: var(--s-1) 0;
}

.four_column_story:last-child {
  border-bottom: transparent;
}

.four_column .news_section__lede {
  padding-bottom: var(--s-2);
  border-bottom: var(--border-light);
  font-size: var(--fs-18);
}

.news_section__four-column div {
  border-bottom: 1px solid var(--border-light);
  position: relative;
}

/***featured content***/

.featured_content {
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;
  margin: var(--s2) 0;
  padding: var(--s2);
  background: #ededed;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translate(-50vw);
  border-bottom: 1px solid var(--border-light);
  box-shadow: 0px 1px 3px 3px #0000003b;

}
.featured_content h2 {
  font-size: var(--fs-30);
  margin-bottom: var(--s-1);
  /*font-family: var(--sans-main), sans-serif;*/
  grid-column: 1/-1;
}

.featured-content div.title_wrap {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.featured_content div.tile_wrap div.title_flag {
  width: var(--s3);
  height: var(--s-1);
  margin-bottom: var(--s-1);
  background: var(--accent-red);
}

.featured_content__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: span 1;
  grid-gap: var(--s1);
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.featured_content__grid div {
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
  position: relative;
}

.featured_content__grid img {
  width: 100%;
}

.featured_content h3 {
  /*font-family: var(--sans-main), sans-serif;*/
  text-align: center;
}

.popular_rail_wrap {
  display: flex;
  flex-direction: column;
  row-gap: var(--s2);
  margin: var(--s2);
}

#evvnt-calendar-widget {
  margin: var(--s1) auto;
}

@media (min-width: 480px) {
  .four_column {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: var(--s0);
  }

  .four_column:first-child {
    padding-left: 0;
  }

  .four-column__title {
    font-size: var(--fs-18);
  }

  .img__four-column {
    position: unset;
    width: 100%;
    margin: 0;
  }



}

@media (min-width: 640px) {
  .news_section {
    border-bottom: 1px solid var(--border-light);
  }
  .news_section img {
    max-width: 100%;
    width: 100%;
    position: static;
    margin: 0 0 var(--s-1);
  }
  .news_section h3 {
    font-size: var(--fs-18);
  }
  .news_section div {
    grid-column: 1/2;
  }
  .news_section ul {
    grid-column: 2/3;
  }
  .news_section ul li {
    margin: 0;
    padding-bottom: var(--s0);
    border-bottom: none;
  }
  .news_section__lede {
    border-bottom: none;
    margin-bottom: 0;
  }
  .one_column .news_section {
    grid-column: span 1;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: var(--s0);
    align-content: baseline;
  }

  .one_column .news_section:last-child {
    margin-top: var(--s0)
  }

  .one_column .news_section h2 {
    grid-column: 1 / -1;
  }

  .one_column .news_section ul {
    padding-left: var(--s0);
    border-left: 1px solid var(--border-light);
  }

  .two_column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--s2);
  }

  .two_column .news_section {
    grid-column-gap: var(--s0);
    border-bottom: none;
  }

  .two_column .news_section {
    padding-right: var(--s2);
    border-right: 1px solid var(--border-light);
  }

  .popular_rail_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--s1);
  }

  .jobs {
    grid-column: 2 / 3;
    align-self: center;
  }

  .featured_content__grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .featured_content__grid li h3 {
    font-size: var(--fs-18);
  }
}

@media (min-width: 768px) {

  .four_column {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: var(--s-1);
  }

  .news_section__four-column {
    padding: 0 var(--s0);
    border-right: 1px solid var(--border-light);
  }

  .news_section__four-column:last-child {
    border-right: transparent;
  }


}

@media (min-width: 960px) {
  .below_the_fold {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: var(--s2);
  }

  .one_column .news_section ul {
    padding-left: var(--s2);
  }

  .news_section {
    grid-column-gap: var(--s2) !important;
  }
  .news_section ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .news_section h3 {
    font-size: var(--fs-18);
  }
  .two_column {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .popular_rail_wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--s1);
    margin: 0;
  }

  .jobs {
    grid-column: 2 / 3;
    align-self: center;
  }

}

.popular_stories h2 {
  font-size: var(--fs-30);
  margin-bottom: var(--s-1);
  /*font-family: var(--sans-main), sans-serif;*/
}
.popular_stories ul {
  padding: 0;
  list-style: none;
  counter-reset: most-read;
}
.popular_stories ul li {
  background-color: transparent;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: dotted 1px var(--border-light);
  counter-increment: most-read;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.popular_stories ul li:before {
  display: block;
  content: counter(most-read);
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  border-radius: 30px;
  margin-top: 5px;
  background-color: var(--accent-blue);
  float: left;
  text-align: center;
  color: white;
  flex-basis: 30px;
  flex-shrink: 0;
}

.popular_stories ul li:last-child {
  border-bottom: none;
}

.popular_stories ul li h4 {
  margin: 10px 20px 0;
  font-size: var(--fs-16--base);
  flex-shrink: 7;
  align-self: baseline;
}

/***LAYOUt***/
.below_the_fold {
  margin: var(--s0) 0;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sidebar .ad_position {
  margin: 1rem auto;
}

@media (min-width: 768px) {
  .one_column {
    grid-row: 1/3;
    display: grid;
    grid-template-columns: 1fr;
  }
  .btf_ad_unit_hoz_small {
    display: none;
  }
  .btf_ad_unit_hoz_wide {
    display: flex;
  }
}
@media (min-width: 960px) {
  .two_column {
    grid-row: 1/2;
    grid-column: 1/2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--s2);
  }
  .two_column .news_section {
    display: flex;
    flex-direction: column;
  }
  .btf_ad_unit_vertical {
    display: flex;
    flex-flow: column nowrap;
  }
  .btf_ad_unit_hoz_wide {
    display: none;
  }
}


/*# sourceMappingURL=below_the_fold.css.map */
