.obit_card{
    display:flex; 
    flex-direction:row-reverse;
    position: relative;
  }
  .obit_card img{
    aspect-ratio:auto;
  }
  div.card_text {
    margin-right:1rem;
  }
  .content-box{
    background-color: var(--grey);
    padding: var(--s0);
    margin:1.5rem 0;
  }
  .archive__date-picker{
    display:flex;
    flex-flow:row wrap;
    align-items: center;
    gap: var(--s0);
    margin:0;
  }
  input.input-date{
    padding: 0;
    width:auto;
  }

  .float-left{
    float:left;
    margin-right:1rem;
  }
  .main__supertitle, .main__title, .main__subtitle {
    font-weight:400;
    font-family: var(--serif-main);
  }
  .main__head{
    margin: 2rem 0;
  }

  .obit_writeup{
    font-size: 1.25rem; 
    line-height: 1.65em; 
    font-family: PT serif,sans-serif;
  }