form{
    margin-bottom:1rem;
  }

  .filter_sort_grid{
    grid-column-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top:1rem;
  }
  .filter_flex{
    display: flex;
    flex-direction:
    column-reverse;
  }
  .sort_flex{
    display: flex;
    flex-direction: column-reverse;
  }
  .title{
    font-size:1.5em;
    font-weight: bold;
  }