.sd-main-content {
    display: grid;
    grid-template-columns: 15% 10% 1fr 10% 15%;
    gap: var(--s0);
    position: relative;
    font-size: var(--fs-18);
    line-height: var(--s2);
}

.article-content {
    font-size: var(--fs-18);
    line-height: var(--s2);
}

.breadcrumb-nav {
    grid-column: 1/-1;
    margin-top: var(--s0);
}

.breadcrumb {
    padding: var(--s-1) var(--s-2);
    list-style: none;
    font-family: var(--sans-main);
}

.breadcrumb li {
    display: inline;
}

.breadcrumb li:not(:last-child):after {
    padding: var(--s-2);
    color: var(--black);
    content: "|";
}

.breadcrumb li a {
    color: var(--blue-links-buttons);
}

.article-header {
    grid-column: 1/-1;
    padding: var(--s0);
    padding-bottom: 0;
    margin: 0 5rem 1rem 5rem;
}

.article-title {
    flex-direction: column;
    row-gap: var(--s0);
}

.article-title span.timestamp {
    font-size: var(--fs-16--base);
    color: var(--text-color);
    text-align: center;
}

.article-title span.timestamp a {
    color: var(--blue-links-buttons);
}

.story__title {
    text-align: center;
    font-size: var(--fs-38);
    font-family: var(--serif-main), serif;
    line-height: 1.1;
}

.subtitle {
    font-size: var(--fs-21);
    text-align: center;
    font-family: var(--serif-main-light);
}

.story__kicker {
    font-family: var(--serif-main), serif;
    margin: 0;
}

.article-lead-photo {
    grid-column: 2/5;
    position: relative;
}

.article-lead-photo img {
    block-size: auto;
    aspect-ratio: 3/2;
    display: block;
    object-fit: contain;
    width: 100%;
}

.article-lead-photo:hover .overlay {
    opacity: 1;
}

.article-body {
    grid-column: 2/3;
    padding: 0 var(--s-1);
  }

.article-body p {
    font-size: var(--fs-20);
}

.article-body a {
    text-decoration: underline;
    text-decoration-color: #949494;
}

.article-body strong {
    font-family: FarnhamHeadlineSemiBold;
}

.article-body blockquote {
    font-family: sans-serif;
    font-size: var(--fs-21);
    font-style: italic;
    margin: 0;
    border-top: 3px solid var(--dark-grey);
    border-bottom: 3px solid var(--dark-grey);
}

blockquote p {
    padding: 1rem;
    background-image: url(https://wehco-files.nyc3.cdn.digitaloceanspaces.com/critical_condition/quote.png);
    background-position: top left;
    background-repeat: no-repeat;
}
blockquote cite {
    display: block;
    text-align: right;
    margin: 0 auto;
    padding-right: 2rem;
}

.intext-photo {
    max-inline-size: 100%;
    block-size: auto;
    aspect-ratio: 3/2;
    object-fit: cover;
    position: relative;
}

.intext-photo img {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 3/2;
    max-width: 100%;
}

.intext-photo:hover .overlay {
    opacity: 1;
}

.overlay {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    padding: 20px;
    text-align: center;
    font-size: var(--fs-16--base);
    line-height: 1.2em;
    /*font-family: var(--sans-main);*/
    font-weight: 350;
}

.article-subtitle {
    font-size: var(--fs-20);
    font-family: var(--serif-main);
}

.inline-video {
    margin: var(--s0) 0;
}

.byline-card {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    grid-gap: var(--s0)
}

.byline-img {
    flex: 0 0 auto;
    width: 30%;
    max-width: 150px;
}

.byline-img img {
    width: 100%;
    height: 10rem;
    object-fit: cover;
    border-radius: 50%;
    object-position: top;
}

.byline-text {
    flex: 1;
    width: 70%;
}

.byline-text p {
    margin: 0;
    font-family: serif;
}

.author {
    margin: 0 0 10px;
    font-size: 1.5em;
}

.article-comments {
    border: 1px solid var(--grey);
    display: flex;
    margin: 2rem auto;
    width: 10rem;
    padding: var(--s-4) 0;
    grid-column: 1/-1;
}

.article-comments h2 {
    font-size: var(--fs-18);
    font-family: var(--ff-sans-serif);
}

.left-rail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.related-card-header {
    border-radius: 3px;
    z-index: 1;
    width: max-content;
    margin: 0 auto -20px auto;
    padding: 2px var(--s0);
    background-color: var(--black);
    color: var(--white);
    position: relative;
    text-align: center;
    /*font-family: var(--sans-main);*/
}

.related-story-card {
    height: auto;
    max-width: 300px;
    margin: var(--s1) 0;
}

.related-img {
    background-color: var(--light-grey);
}

.related-img img {
    aspect-ratio: 3/2;
    object-fit: contain;
    height: auto;
    width: 100%;
}

.related-text {
    background-color: var(--light-grey);
    padding: 0 var(--s0) var(--s0) var(--s0);
    line-height: 1.5rem;
}

.related-title {
    font-size: var(--fs-18);
    font-family: var(--serif-main);
}

.right-rail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.right-rail .ad_position, .article-body .ad_position{
    margin: 1rem auto;
}

.article-body__pre-story-blurb, .article-body__post-story-blurb {
    font-style: italic;
}

.share {
    height: 32px;
}

.inline_text, .inline-text {
    background: #eeeeee;
    border-top: 3px solid var(--dark-grey);
    border-bottom: 3px solid var(--dark-grey);
    margin: 1rem 0;
    padding: 10px;
}

.inline_text ul, ol {
    margin-left: 1rem;
    padding: inherit;
    list-style: revert;
}

.inline_photo {
    line-height: 1.3rem;
    margin: var(--s2) 0;
}
.fr-inner {
    font-size: 0.875rem;
    font-family: serif;
}

@media only screen and (max-width: 768px) {
    .article-header {
        margin: 0;
    }

    .article-lead-photo {
        grid-column: 1/-1;
    }

    .byline-text {
        width: 100%;
    }

    .sd-main-content {
        grid-template-columns: 1fr;
        column-gap: 0;
    }

    .story__title {
        font-size: var(--fs-20);
    }

    .breadcrumb-nav {
        grid-row: 1;
        grid-column: 1/6;
    }

    .byline-card {
        display: block;
    }

    .byline-img {
        float: left;
        margin-right: 0.5rem;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1120px) {
    .article-body {
      grid-column: 1/3;
    }

    .article-content {
        display: grid;
        grid-template-columns: 1fr 2fr 300px;
        grid-gap: 1em;
    }

    .byline-card {
        grid-column: 1 / 3;
    }

    .left-rail {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        grid-row: 5;
        grid-column: 1/6;
    }

    .right-rail {
        grid-column: 3/4;
        grid-row: 1;
    }
  }

@media only screen and (max-width: 1120px) {
    .story__title {
      font-size: var(--fs-32--main-title);
    }
}

@media only screen and (min-width: 1121px) {
    .article-content {
        display: grid;
        grid-template-columns: 300px 1fr 300px;
        grid-gap: 1em;
    }

    .left-align {
        width: 85%!important;
        margin: 1rem 1rem 1rem -40%;
        float: left;
    }

    .left-rail {
        grid-column: 1/2;
        grid-row: 1;
    }

    .right-rail {
        grid-column-end: -1;
        grid-row: 1;
    }

    .byline-card {
        grid-column: 2/3;
    }
}